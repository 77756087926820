import axios from 'axios'
import qs from 'qs'
import {message} from 'antd'

//axios.defaults.baseURL = 'http://192.168.200.13:8900/'//开发环境
axios.defaults.baseURL = process.env.REACT_APP_BASE_API //开发环境
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
    // 判断请求的类型
    // 如果是post请求就把默认参数拼到data里面
    // 如果是get请求就拼到params里面
    if ((config.method === 'post' || config.method === 'put') && config.headers["Content-Type"] === 'application/x-www-form-urlencoded') {
        let data = qs.parse(config.data)
        config.params = {
            ...config.params
        }
        config.data = qs.stringify({
            ...data
        }, {
            arrayFormat: 'brackets'
        })
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
    //对响应数据做些事
    // if (res.config.method === 'get') {
    //     return res
    // }
    // if (res.data.code === '0000') {
    //     // if (res.config.method === 'post') {
    //     return res.data.data
    //     // }
    // }else if (res.data.code === 200){
    //     //umps
    //     return res.data.result
    // } else if (res.data.code === "304") {
    //     // window.location.href = '/#/login'
    // }else if (res.data && !res.data.code){
    //     //umps
    //     return res.data
    // } else {
    //     return Promise.reject(res.data.message)
    // }
    return res.data
}, (error) => {
    // if (error.response.status === 401){
    //     return
    // }
    return Promise.reject('请求异常')
    // return 1
})

function request(params){
    return new Promise((resolve, reject) => {
        axios({
            url: params.url || '',
            method: params.method || 'post',
            params: params.params || {},
            data: params.data || {},
            headers: {
                'Content-Type': params.contentType || 'application/json;charset=utf8',
                'Authorization': localStorage.getItem('token'),
            },
            paramsSerializer: function (param) {
                return qs.stringify(param, {
                    arrayFormat: 'repeat'
                })
            },
        }).then(res => {
            resolve(res)
        }).catch(err => {
            message.error(err || '出问题')
            reject(err)
        })
    })
}
export default request
