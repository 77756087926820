import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import NavigationBar from "./page/NavigationBar";
import Home from "./page/home";
import Product from "./page/product";
import ProductDetails from "./page/product/details";
import ShopCart from "./page/shopCart";
import Order from './page/order';
import MyOrder from './page/myOrder';

function App() {
  return (
      <Router>
          <div className='App'>
              <NavigationBar />
          </div>
          <div style={{ marginTop: 60 }}></div>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/product" element={<Product />} />
              <Route path="/productDetails" element={<ProductDetails />} />
              <Route path="/shopCart" element={<ShopCart />} />
              <Route path="/order" element={<Order />} />
              <Route path="/myOrder" element={<MyOrder/>} />
          </Routes>
      </Router>
  );
}

export default App;
