import React, {useState,useEffect} from "react";
import './index.css'
import {useLocation, useNavigate} from "react-router-dom";
import {Input, message} from "antd";
import ShopRequest from "../../request/shop-request";
const _request = new ShopRequest();

const Order = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const info = JSON.parse(localStorage.getItem('info'));
    const data = location.state.data;
    const [phone, setPhone] = useState(info.phone);
    const [remark, setRemark] = useState(info.remark);


    useEffect(() =>{

    },[])

    const addOrder = () => {
        let param = {
            from: 'cart',
            note: remark,
            phone,
            skuList: []
        };
        data.forEach(i => {
            param.skuList.push({
                productId: i.productId
            })
        })
        _request.addOrder(param).then(res =>{
            message.info('下单成功！');
            navigate('/myOrder');
        })
    }

    return (
        <div className='orderDiv'>
            <div className='orderDetails'>
                <div className='orderInfo'>
                    <div className='orderInfo-div'>
                        <div>手机号码：</div>
                        <Input style={{ width: '200px' }} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className='orderInfo-div'>
                        <div>备注：</div>
                        <Input style={{ width: '200px' }} value={remark} onChange={(e) => setRemark(e.target.value)}/>
                    </div>
                </div>

                <div className='cartOrder' onClick={() => addOrder()}>
                    立即下单
                </div>

                <div className="product-list">
                    {data.map(item => (
                        <div key={item.id} className="product-item">
                            <img src={item.pic} alt={item.productName} />
                            <div className="product-info">
                                <h3>{item.productName}</h3>
                                <p><span>￥</span>{item.spData}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Order
