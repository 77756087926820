import React, {useState, useEffect} from 'react';
import {Carousel, Row, Col, message, Modal, Input} from 'antd';
import ProductRequest from "../../request/product-request";
import AllRequest from "../../request/all";
import { useNavigate,useLocation,useParams,useSearchParams } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';
import './details.css';

const _request = new ProductRequest();
const _all = new AllRequest();

const ProductDetails = (props) => {
    let location = useLocation();
    const navigate = useNavigate();
    const info = JSON.parse(localStorage.getItem('info')) || '';
    // const productId = location.state.id;
    const [picsList, setPicsList] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [breedInfo, setBreedInfo] = useState({});
    const [productData, setProductData] = useState({});
    const [cartTotal, setCartTotal] = useState(0);
    const [isAddCart, setIsAddCart] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [phone, setPhone] = useState(info.phone || '');
    const [remark, setRemark] = useState(info.remark || '');

    useEffect(() => {
        getSex();
        if (location.state) {
            getData(location.state.id);
            localStorage.setItem('detailsId',location.state.id);
            getCarList(location.state.id);
        }else {
            getData(localStorage.getItem('detailsId'));
            getCarList(localStorage.getItem('detailsId'));
        }
    }, [isAddCart]);

    const getData = (id) =>{
        _request.getDetail(id).then(res =>{
            const arr = res.product.albumPics? res.product.albumPics.split(',') : '';
            setPicsList(arr);
            setBreedInfo(res.breedInfo);
            setProductData(res.product);
        })
    }

    const getCarList = (id) => {
        _request.getCarList().then(res =>{
            if (!res.code){
                res.forEach(i => {
                    if (i.productId == id){
                        setIsAddCart(true)
                    }
                })
                setCartTotal(res.length);
            }
        })
    }

    const getSex = () => {
        const param = {
            pageNum: 1,
            pageSize: 999,
            dictType: 'pig_sex_type',
        }
        _all.listData(param).then(response => {
            setGenderData(response.rows)
        });
    }

    const addCart = () => {
        if (!localStorage.getItem('token')) return message.warning('请登录后操作！');
        let param = {
            productId: productData.id,
            productName: productData.name,
            pic: productData.pic,
            spData: productData.price,
        }
        if (!isAddCart){
            _request.addCart(param).then(res => {
                message.info('加入购物车成功！');
                setIsAddCart(true);
            })
        }
    }

    const order = () => {
        // navigate('/order', { state: { data: productData, type: 'order' } });
        if (!localStorage.getItem('token')) return message.warning('请登录后操作！');
        setIsModalOpen(true)
    }

    const goCart = () => {
        if (!localStorage.getItem('token')) return message.warning('登录后可查看！');
        navigate('/shopCart');
    }

    const handleOk = () =>{
        let param = {
            from: 'order',
            note: remark,
            phone,
            skuList: [{
                productId: productData.id
            }],
        }
        _request.addOrder(param).then(res =>{
            message.info('下单成功！');
            setIsModalOpen(false);
            setRemark(info.remark);
            setPhone(info.phone);
            navigate('/myOrder');
        })
    }

    const handleCancel = () =>{
        setIsModalOpen(false);
        setRemark(info.remark);
        setPhone(info.phone);
    }

    return (
        <div className='detailsDiv'>
            <div className='centered-container'>
                <Carousel autoplay>
                    {
                        picsList.length > 0 ?picsList.map(item =>{
                            return <div className='picsDiv'>
                                <img src={item}/>
                            </div>
                        }) : ''
                    }
                </Carousel>

                <div className='introduce'>
                    <div className='price'>
                        <div className='unit'>￥</div>
                        <h1 className='priceDiv'>{productData.price}</h1>
                        <div className='unit'>/{productData.unit}</div>
                    </div>
                    <div className='detailHtml'>
                        {productData.name}
                    </div>
                </div>

                <div className="individual-info">
                    <div className='infoTitle'>
                        <div className='staff'></div>
                        个体信息
                    </div>
                    <Row>
                        <Col span={8}>
                            猪只耳号：{ breedInfo.earIdentify || '-' }
                        </Col>
                        <Col span={8}>
                            电子耳标：{ breedInfo.earNumber || '-' }
                        </Col>
                        <Col span={8}>
                            种猪等级：{ breedInfo.level || '-' }
                        </Col>
                        <Col span={8}>
                            出生日期：{ breedInfo.birthday || '-' }
                        </Col>
                        <Col span={8}>
                            品种类型：{ breedInfo.breed || '-' }
                        </Col>
                        <Col span={8}>
                            性别：{ genderData.map(i => {
                            if (breedInfo.sex == i.dictValue){
                                return i.dictLabel
                            }
                        }) }
                        </Col>
                        <Col span={8}>
                            断奶天数：{ breedInfo.weanDay ? breedInfo.weanDay + ' 天' : '-' }
                        </Col>
                        <Col span={8}>
                            左乳头数：{ breedInfo.leftTeatNum ? breedInfo.leftTeatNum + ' 个' : '-' }
                        </Col>
                        <Col span={8}>
                            右乳头数：{ breedInfo.rightTeatNum ? breedInfo.rightTeatNum + ' 个' : '-' }
                        </Col>
                        <Col span={8}>
                            体长：{ breedInfo.bodyLength ? breedInfo.bodyLength + ' cm' : '-' }
                        </Col>
                        <Col span={8}>
                            体宽：{ breedInfo.bodyWidth ? breedInfo.bodyWidth + ' cm' : '-' }
                        </Col>
                        <Col span={8}>
                            体高：{ breedInfo.adg ? breedInfo.adg + ' cm' : '-' }
                        </Col>
                        <Col span={8}>
                            体型外貌：{ breedInfo.shapeScore ? breedInfo.shapeScore + ' (百分制)' : '-' }
                        </Col>
                    </Row>
                </div>

                <div className="measure-info">
                    <div className='infoTitle'>
                        <div className='staff'></div>
                        测定信息
                    </div>
                    <Row>
                        <Col span={8}>
                            料肉比：{ breedInfo.fcr || '-' }
                        </Col>
                        <Col span={8}>
                            日增重：{ breedInfo.adg ? breedInfo.adg + ' g' : '-' }
                        </Col>
                        <Col span={8}>
                            日采食：{ breedInfo.adfi ? breedInfo.adfi + ' kg' : '-' }
                        </Col>
                        <Col span={8}>
                            始测日龄：{ breedInfo.startAge ? breedInfo.startAge + ' 天' : '-' }
                        </Col>
                        <Col span={8}>
                            结测日龄：{ breedInfo.endAge ? breedInfo.endAge + ' 天' : '-' }
                        </Col>
                        <Col span={8}>
                            测定周期：{ breedInfo.realFeedDays ? breedInfo.realFeedDays + ' 天' : '-' }
                        </Col>
                        <Col span={8}>
                            背膘厚度：{ breedInfo.backDepth ? breedInfo.backDepth + ' mm' : '-' }
                        </Col>
                        <Col span={8}>
                            矫正背膘厚度：{ breedInfo.reBackDepth ? breedInfo.reBackDepth + ' mm' : '-' }
                        </Col>
                    </Row>
                </div>

                <div className="measure-info">
                    <div className='infoTitle'>
                        <div className='staff'></div>
                        描述
                    </div>
                    <div style={{ marginTop: '10px',marginLeft: '1rem' }}>{ productData.detailHtml }</div>
                </div>
                <div className='bottom'>
                    <div className="shopping-cart-icon" data-count={cartTotal}>
                        <ShoppingCartOutlined onClick={() => goCart()}/>
                    </div>
                    <div>
                        <div className={isAddCart?'joinCart disabled': 'joinCart'} onClick={() => addCart()}>
                            加入购物车
                        </div>
                        <div className='order' onClick={() => order()}>
                            立即下单
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="确认下单" width='400px' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText='确认下单' cancelText='取消'>
                <div className='order-modal-div'>
                    <div>手机号码：</div>
                    <Input value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className='order-modal-div'>
                    <div>备注：</div>
                    <Input value={remark} onChange={(e) => setRemark(e.target.value)}/>
                </div>
            </Modal>
        </div>
    );
};

export default ProductDetails;
