import React, {useState, useEffect} from 'react';
import { Carousel } from 'antd';
import HomeRequest from "../../request/home-request";
import { useNavigate } from 'react-router-dom';
import appImg from '../../img/app.png'
import comingSoon from '../../img/comingSoon.png'
import './index.css';

const _request = new HomeRequest();

const Home = () => {
    const navigate = useNavigate();

    const [picsList, setPicsList] = useState([])
    const [introduce, setIntroduce] = useState('')
    const [contact, setContact] = useState('')

    useEffect(() => {
        getPics();
        getList();
    }, []);

    const getPics = () =>{
        _request.getPics().then(res =>{
            const arr = res.split(',')
            setPicsList(arr)
        })
    }

    const getList = () =>{
        _request.getList().then(res =>{
            res.rows.forEach(item =>{
                if(item.noticeType === '1'){
                    //公司介绍
                    setIntroduce(item.noticeContent)
                }
                if(item.noticeType === '2'){
                    //联系我们
                    setContact(item.noticeContent)
                }
                if(item.noticeType === '3'){
                    //客服电话

                }
            })
        })
    }

    const goProduct = () => {
        navigate('/product');
    }


    return (
        <div className='homeDiv'>
            <Carousel autoplay>
                {
                    picsList.map(item =>{
                        return <div className='picsDiv'>
                            <img src={item}/>
                        </div>
                    })
                }
            </Carousel>

            <div className="introduce">
                <h1>公司介绍</h1>
                <div className="introduce_div" dangerouslySetInnerHTML={{ __html: introduce }}></div>
            </div>

            <div className="contact">
                <h1>联系我们</h1>
                <div className="contact_div" dangerouslySetInnerHTML={{ __html: contact }}></div>
            </div>


            <div className="message" onClick={goProduct}>
                了解产品信息
            </div>

            <div className="contact">
                {/*<h1>APP下载</h1>*/}
                <div className='appImg'>
                    <div>
                        <img src={appImg} height='150' alt="app"/>
                        <div>Android</div>
                    </div>
                    <div>
                        <img src={comingSoon} height='150' alt="comingSoon"/>
                        <div>iOS</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
