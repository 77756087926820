import request from '../axios.js'

class ProductRequest{
    //获取数据
    getList(param) {
        return request({
            url: 'no-auth/product/list?page=0&size=999',
            method: 'post',
            data: param,
        })
    }
    //获取详情
    getDetail(id) {
        return request({
            url: 'no-auth/product/detail/' + id,
            method: 'get',
        })
    }
    //加入购物车
    addCart(param) {
        return request({
            url: 'h5/cart/add',
            method: 'post',
            data: param
        })
    }
    //加入购物车
    getCarList(param) {
        return request({
            url: 'h5/cart/list',
            method: 'get',
            data: param
        })
    }
    //添加订单
    addOrder(param) {
        return request({
            url: 'h5/order/add',
            method: 'post',
            data: param
        })
    }
}

export default ProductRequest
