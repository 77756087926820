import locale from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import moment from 'moment';
import Layout, {Content, Header} from "antd/es/layout/layout";
import {useEffect, useState} from "react";
import {Button, ConfigProvider, DatePicker, Divider, Form, Image, Input, message, Pagination, Spin, Table} from "antd";
import FormItem from "antd/es/form/FormItem";
import OrderRequest from "../../../request/order-request";
import "../index.css"
import {useNavigate} from "react-router-dom";
import ProductRequest from "../../../request/product-request";
const orderRequest = new OrderRequest();
const productRequest = new ProductRequest();

export default function CloseOrder () {

    useEffect(()=> {
        doSearch();
    },[]);

    const navigate = useNavigate();
    const columns = [
        {
            title: '订单编号',
            dataIndex: 'orderSn',
            key: 'orderSn',
            width: 249
        },
        {
            title: '商品详情',
            dataIndex: 'orderItemList',
            width: 300,
            key: 'orderItemList',
            render: (record) => {
                if (record && record.length>0) {
                    let i = 0 ;
                    return <div>
                        {
                            record.map((data) => {
                                if (i===0) {
                                    i++;
                                    return <div key={data.id + i}>
                                        <Image width={30} src={data.pic}/> <a style={{fontWeight: 'bold'}} onClick={toDetail.bind(this,data.productId)}>({data.productName})</a> <span style={{fontWeight: 'bold',color:'red'}}>￥{data.salePrice} * {data.quantity}</span>
                                    </div>;
                                } else {
                                    i++;
                                    return <div key={data.id + i} style={{marginTop: '8px'}}>
                                        <Image width={30} src={data.pic}/> <a style={{fontWeight: 'bold'}} onClick={toDetail.bind(this,data.productId)}>({data.productName})</a> <span style={{fontWeight: 'bold',color:'red'}}>￥{data.salePrice} * {data.quantity}</span>
                                    </div>;
                                }
                            })
                        }
                    </div>
                } else {
                    return '--';
                }
            }
        },
        {
            title: '下单时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 180,
            render: (createTime)=> {
                return createTime ? createTime : '--';
            }
        },
        {
            title: '取消时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            width: 180,
            render: (updateTime)=> {
                return updateTime ? updateTime : '--';
            }
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
            width: 140,
            render: (status)=> {
                if (status === 0) return <div className={'default-tab created-tab'}>已下单</div>;
                if (status === 3) return <div className={'default-tab complete-tab'}>已完成</div>;
                if (status === 4) return <div className={'default-tab close-tab'}>已取消</div>;
            }
        }
    ]


    const [orders,setOrders] = useState([]);
    const [total,setTotal] = useState(undefined);
    const [loading,setLoading] = useState(false);
    const [startTime,setStartTime] = useState(undefined);
    const [endTime,setEndTime] = useState(undefined);
    const [orderSn,setOrderSn] = useState('');
    let param = {};
    const doSearch = ()=> {
        setLoading(true);
        param.orderSn = orderSn.trim();
        if (startTime) {
            param.startUpdateTime = startTime.format('YYYY-MM-DD') + ' 00:00:00';
        }
        if (endTime) {
            param.endUpdateTime =  endTime.format('YYYY-MM-DD') + ' 23:59:59';
        }
        orderRequest.getOrderList(4,param).then((res)=>{
            if (res) {
                setOrders(res.content);
                setTotal(res.totalElements);
            }
        }).finally(()=>{
            setLoading(false);
        });
    }

    const toDetail = (id) => {
        productRequest.getDetail(id).then((res)=> {
            if (!res || !res.product || res.product.publishStatus===0) {
                message.warning("该商品已下架").then(r => (r));
            } else {
                navigate('/productDetails', { state: { id: id } });
            }
        })
    }

    const onPageChange = (page, pageSize) => {
        param.page = page-1;
        param.size = pageSize
        doSearch();
    }


    return (
        <div>
            <Layout>
                <Header style={{backgroundColor:'#FFFFFF',padding:'8px'}}>
                    <Form layout={'inline'}>
                        <FormItem label={'订单编号'}>
                            <Input allowClear value={orderSn} placeholder={'请输入订单编号'} onChange={(e)=>{setOrderSn(e.target.value)}}/>
                        </FormItem>
                        <FormItem label={'取消开始日期'}>
                            <ConfigProvider locale={locale}>
                                <DatePicker format={'YYYY-MM-DD'} value={startTime} onChange={setStartTime} placeholder={'请选择日期'}>
                                </DatePicker>
                            </ConfigProvider>
                        </FormItem>
                        <FormItem label={'取消结束日期'}>
                            <ConfigProvider locale={locale}>
                                <DatePicker format={'YYYY-MM-DD'} value={endTime} onChange={setEndTime} placeholder={'请选择日期'}>
                                </DatePicker>
                            </ConfigProvider>
                        </FormItem>
                        <FormItem>
                            <Button type={'primary'} onClick={doSearch}>
                                查询
                            </Button>
                        </FormItem>
                    </Form>
                </Header>
                <div style={{backgroundColor:'#FFFFFF'}}>
                    <Divider></Divider>
                </div>
                <Content  style={{backgroundColor:'#FFFFFF'}}>
                    <div>
                        <Spin tip={"loading..."} size={'large'} spinning={loading}>
                            <div>
                                <Table dataSource={orders} columns={columns} pagination={false}/>
                            </div>
                            <Pagination onChange={onPageChange} style={{marginTop:'16px',float:'right'}} defaultCurrent={1} defaultPageSize={10} total={total}/>
                        </Spin>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}
