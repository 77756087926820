import React, {useState, useEffect} from "react";
import {Col, Row, Modal, Button, Checkbox, Divider, message, Input} from 'antd';
import ShopRequest from "../../request/shop-request";
import { useNavigate,useLocation,useParams,useSearchParams } from 'react-router-dom';
import './index.css'

const _request = new ShopRequest();


function ShopCart() {
    const info = JSON.parse(localStorage.getItem('info'));
    const navigate = useNavigate();
    const [total,setTotal] = useState(0)
    const [list,setList] = useState([])
    const [isModalOpen,setIsModalOpen] = useState(false)
    const [isOrderOpen,setIsOrderOpen] = useState(false)
    // const [indeterminate, setIndeterminate] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [addOrderList, setAddOrderList] = useState([]);
    const [delID, setDelId] = useState(false);
    const [delIndex, setDelIndex] = useState(0);
    const [phone, setPhone] = useState(info.phone);
    const [remark, setRemark] = useState(info.remark);

    const indeterminate = checkedList.length > 0 && checkedList.length < list.length;

    useEffect(() => {
        getCartList();
    },[])

    const getCartList = () => {
        _request.getCartList().then(res =>{
            setTotal(res.length);
            setList(res);
        })
    }

    const onCheckAllChange = (e) => {
        const { checked } = e.target;
        const allItemIds = list.map(item => item.id);
        setCheckedList(checked ? allItemIds : []);
        setAddOrderList(checked ? list : []);
    };

    const onChangeItem = (itemId,item) => {
        let arr = [...addOrderList];
        let flag = false;
        let index2 = 0;
        arr.forEach((i,j) => {
            if (i.id === itemId){
                flag = true;
                index2 = j
            }
        })
        const index = checkedList.indexOf(itemId);
        const newCheckedList = index !== -1
            ? checkedList.filter(id => id !== itemId)
            : [...checkedList, itemId];
        if (flag){
            // arr.filter(i => i.id === itemId);
            arr.splice(index2,1);
        }else {
            arr.push(item);
        }
        setAddOrderList(arr);
        setCheckedList(newCheckedList);
    };

    const delCart = (id,index) => {
        let arr = [...checkedList];
        if (id){
            setDelId(id);
            setDelIndex(index)
        }else {
            if (arr.length === 0) return message.warning('请选择商品！');
        }
        setIsModalOpen(true);
    }

    const toDetails = (item) => {
        navigate('/productDetails', { state: { id: item.productId } });
    }

    const order = () => {
        let arr = [...checkedList];
        if (arr.length === 0) return message.warning('请选择商品！');
        setIsOrderOpen(true);
        // navigate('/order', { state: { data: addOrderList } });
    }

    const handleOk = () => {
        let ids = '';
        let arr = [...checkedList];
        if (delID) {
            ids = delID + '';
            arr.splice(delIndex,1);
            setCheckedList(arr);
        }else {
            ids = arr.join(',');
            setCheckedList([])
        }
        _request.removeCart(ids).then(res =>{
            getCartList();
            message.info('删除成功！');
            setDelId(false);
            setIsModalOpen(false);
        })
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setDelId(false);
    }

    const orderHandleCancel = () => {
        setIsOrderOpen(false);
        setRemark(info.remark);
        setPhone(info.phone);
    }

    const orderHandleOk = () => {
        let param = {
            from: 'cart',
            note: remark,
            phone,
            skuList: []
        };
        addOrderList.forEach(i => {
            param.skuList.push({
                productId: i.productId
            })
        })
        _request.addOrder(param).then(res =>{
            message.info('下单成功！');
            setIsOrderOpen(false);
            setRemark(info.remark);
            setPhone(info.phone);
            getCartList();
            navigate('/myOrder');
        })
    }

    return (
        <div className='cartDiv'>
            {
                total > 0 ?
                    <div className='cartContent'>
                        <div className='cartTitle'>
                            <div>
                                共 {total} 件商品
                            </div>
                            <div className='delOptFor' onClick={() => delCart()}>
                                删除选中商品
                            </div>
                            <div className='cartOrder' onClick={() => order()}>
                                立即下单
                            </div>
                        </div>
                        <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkedList.length === list.length}>
                            全选
                        </Checkbox>
                        <Divider />
                        <div className="product-list">
                            {list.map((item,index) => (
                                <div key={item.id} className="product-item">
                                    <Checkbox
                                        value={item.id}
                                        checked={checkedList.includes(item.id)}
                                        onChange={() => onChangeItem(item.id,item)} />
                                    <img src={item.pic} alt={item.productName} />
                                    <div className="product-info">
                                        <h3 className='info-name' onClick={() => toDetails(item)}>{item.productName}</h3>
                                        <p><span>￥</span>{item.spData}</p>
                                    </div>
                                    <div className='cartDel' onClick={() => delCart(item.id,index)}>
                                        删除
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/*{list.map(item => (*/}
                        {/*    require (*/}
                        {/*        // <li key={item.productId}>*/}
                        {/*        //     <input*/}
                        {/*        //         type="checkbox"*/}
                        {/*        //         checked={item.selected}*/}
                        {/*        //         onChange={() => handleToggleSelect(item.id)}*/}
                        {/*        //     />*/}
                        {/*        //     <img width='100' src={item.pic} alt={item.productName} />*/}
                        {/*        //     <span>{item.productName}</span>*/}
                        {/*        //     <span>￥{item.spData}</span>*/}
                        {/*        // </li>*/}
                        {/*<CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />*/}
                        {/*)*/}
                        {/*))}*/}
                    </div>
                    : <div style={{ width: '100%',textAlign: 'center',paddingTop: '20%' }}>
                        购物车是空的
                    </div>
            }
            <Modal title="确认删除吗？" width='300px' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText='确认' cancelText='取消'></Modal>
            <Modal title="确认下单" width='400px' open={isOrderOpen} onOk={orderHandleOk} onCancel={orderHandleCancel} okText='确认下单' cancelText='取消'>
                <div className='order-modal-div'>
                    <div>手机号码：</div>
                    <Input value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className='order-modal-div'>
                    <div>备注：</div>
                    <Input value={remark} onChange={(e) => setRemark(e.target.value)}/>
                </div>
            </Modal>
        </div>
    )
}

export default ShopCart

