import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import AllRequest from "../../request/all";
import {Modal, Button, Input, message, Space,Dropdown} from 'antd';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import './index.css';
import { LogoutOutlined ,SolutionOutlined, WarningOutlined,ShoppingCartOutlined} from '@ant-design/icons';

const _all = new AllRequest();

const NavigationBar = () => {
    const navigate = useNavigate();
    const [logo,setLogo] = useState('')
    const [name,setName] = useState('')
    const [open, setOpen] = useState(false);
    const [isLogoutOpen, setIsLogoutOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(localStorage.getItem('token'));
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [countdown, setCountdown] = useState(0);
    const [uuid, setUuid] = useState('');

    useEffect(()=>{
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [countdown]);

    useEffect(() =>{
        getLogo();
        getName();
    },[isLogin])

    const getLogo = () => {
        _all.getLogo().then(res =>{
            setLogo(res)
        })
    }

    const getName = () => {
        _all.getName().then(res =>{
            setName(res.rows[0].noticeContent)
        })
    }

    const login = () => {
        setOpen(true);
    }

    const onCancel = () => {
        setOpen(false);
    }

    const handleLogin = () => {
        // 在这里执行手机号短信验证的逻辑
        // 这里只是简单的展示一下
        if (phoneNumber && verificationCode) {
            let obj = {
                mobile: phoneNumber,
                code: verificationCode,
                uuid: uuid,
            };
            let param = JSON.stringify(obj);
            _all.login(btoa(param)).then(res =>{
                if (res.code){
                    message.error(res.msg);
                }else {
                    localStorage.setItem('token',res.token);
                    setIsLogin(res.token);
                    message.success('登录成功');
                    setOpen(false);
                    getInfo();
                }
            })
        } else {
            message.error('请输入有效的手机号和验证码');
        }
    };

    const getInfo = () => {
        _all.getInfo().then(res =>{
            localStorage.setItem('info',JSON.stringify(res))
            window.location.reload();    // 重新加载当前页面
        })
    }

    const handleSendCode = () => {
        // 在这里执行发送短信验证码的逻辑
        // 这里只是简单的展示一下
        if (phoneNumber && countdown === 0) {
            // message.success('验证码已发送');
            setCountdown(60); // 开始倒计时
            const phoneNumberBase64 = btoa(phoneNumber);
            _all.sendAliyun(phoneNumberBase64).then(res => {
                setUuid(res.uuid);
            })
        } else if (countdown > 0) {
            message.info(`请在${countdown}秒后再次发送验证码`);
        } else {
            message.error('请输入有效的手机号');
        }
    };

    const logoutHandle = () => {
        navigate('/');
        localStorage.clear();
        setIsLogoutOpen(false);
        setIsLogin(null);
    }

    const logoutCancelHandle = () => {
        setIsLogoutOpen(false);
    }

    const myItems = [
        {
            key: '2',
            label: <div style={{fontWeight:'bold'}}><Link to="/myOrder"><SolutionOutlined />  &nbsp;我的订单</Link></div>
        },
        {
            key: '1',
            label: <div style={{fontWeight:'bold'}}><Link to="/shopCart"><ShoppingCartOutlined /> &nbsp;<label>购物车</label></Link></div>
        },
        {
            key: '3',
            label: <div style={{fontWeight:'bold'}}><a onClick={()=>{
                setIsLogoutOpen(true);
            }
            }><LogoutOutlined /> &nbsp;<label>注&nbsp;&nbsp;&nbsp;&nbsp;销</label></a></div>
        }
    ]

    return (
        <div id="navbar">
            <div className='navbarDiv'>
                <Link to="/" className='logo'>
                    <img src={logo} alt={name} height='60'/>
                    <div>{name}</div>
                </Link>
                <nav>
                    <ul>
                        <li>
                            <Link to="/">首页</Link>
                        </li>
                        <li>
                            <Link to="/product">产品</Link>
                        </li>
                        {/*<li>
                            <Link to="/shopCart">购物车</Link>
                        </li>*/}
                        <li>
                            {/*<Link to="/myOrder-account">我的</Link>*/}
                            {/*<Link to="/myOrder">我的</Link>*/}
                            {
                                isLogin ?
                                    <Dropdown menu={{items: myItems}}>
                                        <div>
                                            <a>
                                                <Space>
                                                    我的
                                                </Space>
                                            </a>
                                        </div>
                                    </Dropdown>: <Link onClick={login}>登录</Link>
                            }
                        </li>
                    </ul>
                </nav>
            </div>

            <Modal title="手机号短信验证登录" open={open} onCancel={onCancel} footer={null} className="login-modal">
                <div>
                    <Input
                        placeholder="请输入手机号"
                        value={phoneNumber}
                        size='Large'
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                </div>
                <div className="code-input">
                    <Input
                        placeholder="请输入验证码"
                        value={verificationCode}
                        size='Large'
                        onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <Button onClick={handleSendCode} size='Large' disabled={countdown > 0}>
                        {countdown > 0 ? `${countdown} 秒后可以重发` : '发送验证码'}
                    </Button>
                </div>
                <div>
                    <Button type="primary" onClick={handleLogin}>
                        登录
                    </Button>
                </div>
            </Modal>

            <Modal okText={'确认'} cancelText={'取消'} title={<span><WarningOutlined style={{color:'#FAAD14'}} /> 注销</span>} open={isLogoutOpen} onOk={logoutHandle} width={300} onCancel={logoutCancelHandle}>
                <p style={{fontSize: '14px'}}>确定注销当前账号？</p>
            </Modal>
        </div>
    );
};

export default NavigationBar;
