import React, {useState, useEffect} from 'react';
import { Col, Row, Input, Button, InputNumber } from 'antd';
import ProductRequest from "../../request/product-request";
import { useNavigate,useLocation,useParams,useSearchParams } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import AllRequest from "../../request/all";
import './index.css';

const _request = new ProductRequest();
const _all = new AllRequest();
const { Search } = Input;
const Product = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([])
    const [expanded1, setExpanded1] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [expanded3, setExpanded3] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedStrain, setSelectedStrain] = useState(null);
    const [selectedSex, setSelectedSex] = useState(null);
    const [strainData, setStrainData] = useState([])
    const [genderData, setGenderData] = useState([])
    const [searchData, setSearchData] = useState([])
    const [adgMin, setAdgMin] = useState(null)
    const [adgMax, setAdgMax] = useState(null)
    const [fcrMax, setFcrMax] = useState(null)
    const [fcrMin, setFcrMin] = useState(null)
    const [keyword, setKeyword] = useState(null)

    useEffect(() =>{
        getList();
    }, [searchData,keyword])

    useEffect(() =>{
        getStrain();
        getSex();
    }, [])

    const getList = (type) =>{
        let param = {
            page: 0,
            size: 999,
            publishStatus: 1,
            adgMin,
            adgMax,
            fcrMax,
            fcrMin,
            search: keyword
        };
        if (type){
            param = {
                page: 0,
                size: 999,
                publishStatus: 1,
                search: keyword
            };
        }
        if (searchData.length > 0){
            searchData.forEach(i =>{
                if (i.type === '1'){
                    param.level = i.id
                }
                if (i.type === '2'){
                    param.breed = i.id
                }
                if (i.type === '3'){
                    param.sex = i.id
                }
            })
        }
        _request.getList(param).then(res =>{
            setList(res.content);
        })
    }
    /** 获取种猪品系 */
    const getStrain = () => {
        const param = {
            pageNum: 1,
            pageSize: 999,
            dictType: 'breed_type',
        }
        _all.listData(param).then(response => {
            setStrainData(response.rows)
        });
    }
    const getSex = () => {
        const param = {
            pageNum: 1,
            pageSize: 999,
            dictType: 'pig_sex_type',
        }
        _all.listData(param).then(response => {
            setGenderData(response.rows)
        });
    }

    const goDetails = (e) => {
        navigate('/productDetails', { state: { id: e.id } });
    }
    const allTypes = ['特等', '一等', '二等', '三等'];

    const displayedTypes = allTypes;

    const handleTypeClick = (type) => {
        let arr = [...searchData]
        arr.push({
            id: type,
            name: type,
            optionName: '种猪等级：',
            type: '1'
        });
        setExpanded1(true);
        setSearchData(arr)
        setSelectedType(type);
    };

    const handleStrainClick = (item) => {
        let arr = [...searchData]
        arr.push({
            id: item.dictValue,
            name: item.dictLabel,
            optionName: '品系：',
            type: '2'
        });
        setExpanded2(true);
        setSearchData(arr);
        setSelectedStrain(item.dictValue);
    };

    const handleGenderClick = (item) => {
        let arr = [...searchData];
        arr.push({
            id: item.dictValue,
            name: item.dictLabel,
            optionName: '性别：',
            type: '3'
        });
        setExpanded3(true);
        setSearchData(arr);
        setSelectedSex(item.dictValue);
    };

    const closeSearch = (e,i) => {
        let arr = [...searchData];
        arr.splice(i, 1);
        setSearchData(arr);
        // getList();
        if (e.type === '1'){
            setExpanded1(false);
        }
        if (e.type === '2'){
            setExpanded2(false);
        }
        if (e.type === '3'){
            setExpanded3(false);
        }
    }

    const onSearch = (e) => {
        setKeyword(e)
    }

    return (
        <div className='productDiv'>
            {/*{allTypes.length > 10 && (*/}
            {/*    <Button type="link" onClick={() => setExpanded(!expanded)}>*/}
            {/*        {expanded ? '收起' : '更多'}*/}
            {/*    </Button>*/}
            {/*)}*/}
            <div className='centered-container'>
                <div className='search'>
                    <Search placeholder="请输入关键字进行搜索" size="large" enterButton  allowClear onSearch={onSearch} style={{ width: '100%' }} />
                </div>
                {
                    searchData.length > 0?
                        <div className='search-criteria'>
                            {
                                searchData.map((item,index) =>{
                                    return <div>
                                        {item.optionName} {item.name} <CloseOutlined onClick={() => closeSearch(item,index)} className='search-icon' />
                                    </div>
                                })
                            }
                        </div>
                        :''
                }
                {!expanded1?<div className='filtrate'>
                    <div className='title'>种猪等级:</div>
                    <div className='content'>
                        {displayedTypes.map((type, index) => (
                            <div
                                key={index}
                                className={`type ${selectedType === type ? 'selected' : ''}`}
                                onClick={() => handleTypeClick(type)}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>:''}
                {!expanded2?<div className='filtrate'>
                    <div className='title'>品系:</div>
                    <div className='content'>
                        {strainData.map((item, index) => (
                            <div
                                key={index}
                                className={`type ${selectedStrain === item.dictValue ? 'selected' : ''}`}
                                onClick={() => handleStrainClick(item)}
                            >
                                {item.dictLabel}
                            </div>
                        ))}
                    </div>
                </div>:''}
                {!expanded3?<div className='filtrate'>
                    <div className='title'>性别:</div>
                    <div className='content'>
                        {genderData.map((item, index) => (
                            <div
                                key={index}
                                className={`type ${selectedSex === item.dictValue ? 'selected' : ''}`}
                                onClick={() => handleGenderClick(item)}
                            >
                                {item.dictLabel}
                            </div>
                        ))}
                    </div>
                </div>:''}
                <div className='filtrate'>
                    <div className='title'>其他选项:</div>
                    <div className='content'>
                        <div className='number'>
                            <span>料肉比：</span>
                            <InputNumber min={0} value={fcrMin} placeholder='最小' onChange={(e) => setFcrMin(e)}/>
                            —
                            <InputNumber min={0} value={fcrMax} placeholder='最大' onChange={(e) => setFcrMax(e)}/>
                        </div>
                        <div className='number'>
                            <span>日增重(g)：</span>
                            <InputNumber min={0} value={adgMin} placeholder='最小' onChange={(e) => setAdgMin(e)}/>
                            —
                            <InputNumber min={0} value={adgMax} placeholder='最大' onChange={(e) => setAdgMax(e)}/>
                            <span className='query' onClick={() => getList()}>查询</span>
                            <span className='reset' onClick={() => {
                                setFcrMin(null)
                                setFcrMax(null)
                                setAdgMin(null)
                                setAdgMax(null)
                                getList('reset')
                            }}>重置</span>
                        </div>
                    </div>
                </div>

                <Row gutter={20}>
                    {
                        list.map(item => {
                            return <Col span={6} onClick={goDetails.bind(this,item)}>
                                <div className='product-card'>
                                    <div className='imgDiv'>
                                        <img src={item.pic} alt={item.name} className="product-image" />
                                    </div>
                                    <div className='product-content'>
                                        <div className='product-name'>
                                            <div className='level' style={{ backgroundColor: item.breedInfo.level === '特等'?'red':'#ffcc00' }}>{item.breedInfo.level}</div>
                                            &nbsp;
                                            <div className='product-name-div' title={item.name}>
                                                {item.name}
                                            </div>
                                        </div>
                                        {/*<div>*/}
                                        {/*    &nbsp;*/}
                                        {/*    {item.breedInfo.breed}*/}
                                        {/*    &nbsp;*/}
                                        {/*    {*/}
                                        {/*        genderData.map(i => {*/}
                                        {/*            if(i.dictValue == item.breedInfo.sex) {*/}
                                        {/*                return i.dictLabel;*/}
                                        {/*            }*/}
                                        {/*        })*/}
                                        {/*    }*/}
                                        {/*</div>*/}
                                        {/*<p>￥ {item.price}</p>*/}
                                        <div className='fcr'>
                                            {item.breedInfo.breed}
                                            { item.breedInfo.sex === 0 || item.breedInfo.sex? ' | ' : ''}
                                            {
                                                item.breedInfo.sex === 0 || item.breedInfo.sex?
                                                genderData.map(i => {
                                                    if(i.dictValue == item.breedInfo.sex) {
                                                        return i.dictLabel;
                                                    }
                                                }):''
                                            }
                                            { item.breedInfo.sex === 0 || item.breedInfo.sex? ' | ' : ''}&nbsp;
                                            {item.breedInfo.fcr ? '料肉比 ' + item.breedInfo.fcr + ' | ' : ''}
                                            {item.breedInfo.adfi ? '日采食 ' + item.breedInfo.adfi + 'kg | ' : ''}
                                            {item.breedInfo.adg ? '日增重 ' + item.breedInfo.adg + 'g | ' : ''}
                                            {/*日采食 {item.breedInfo.adfi}kg | 日增重 {item.breedInfo.adg}g*/}
                                        </div>
                                        <div className='price'>
                                            <div className='unit'>￥</div>
                                            <h2 className='priceDiv'>{item.price}</h2>
                                            <div className='unit'>/{item.unit}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        })
                    }
                </Row>
            </div>
        </div>
    );
};

export default Product;
