import {Layout, Tabs} from "antd";
import {Content} from "antd/es/layout/layout";
import AllOrder from "./components/AllOrder";
import PlaceOrder from "./components/PlaceOrder";
import ReceiveOrder from "./components/ReceiveOrder";
import CloseOrder from "./components/CloseOrder";
const MyOrder = () => {
    const items = [
        {
            label: `全部`,
            key: -1,
            children: <AllOrder/>,
        },
        {
            label: `已下单`,
            key: 0,
            children: <PlaceOrder/>,
        },
        {
            label: `已完成`,
            key: 3,
            children: <ReceiveOrder/>,
        },
        {
            label: `已取消`,
            key: 4,
            children: <CloseOrder/>,
        }
    ]

    return (
        <Layout style={{minWidth:'1200px',backgroundColor:'#FFFFFF'}}>
            <Content>
                <div style={{margin:'auto',minWith:'1000px',width:'80%',padding:'32px 16px 16px 16px'}}>
                    <Tabs size={"large"} type="card" items={items}/>
                </div>
            </Content>
        </Layout>
    )
}


export default MyOrder;
