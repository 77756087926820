import request from '../axios.js'

class ShopRequest{
    //获取购物车订单列表
    getCartList(param) {
        return request({
            url: 'h5/cart/list',
            method: 'get',
            params: param,
        })
    }
    //获取购物车订单列表
    removeCart(param) {
        return request({
            url: 'h5/cart/remove',
            method: 'delete',
            contentType: 'text/plain',
            data: param
        })
    }
    //添加订单
    addOrder(param) {
        return request({
            url: 'h5/order/add',
            method: 'post',
            data: param
        })
    }
}

export default ShopRequest
