import request from '../axios.js'

export default class OrderRequest {
    getOrderList(status,param) {
        param.status = status;
        param.page = !param.page ? 0 : param.page;
        param.size = !param.size ? 10 : param.size;
        return request({
            url: 'h5/order/page',
            method: 'get',
            params: param,
        })
    }
}
