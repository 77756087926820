import request from '../axios.js'

class HomeRequest{
    //获取首页轮播图
    getPics(param) {
        return request({
            url: 'pms/breed/getPics?type=home_carousel_pic',
            method: 'get',
        })
    }
    //获取公司介绍
    getList(param) {
        return request({
            url: 'system/notice/list?pageNum=1&pageSize=10',
            method: 'get',
        })
    }
}

export default HomeRequest
